import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { FormInstance } from 'antd';

import { ProductProps } from './products.interface';

export interface Shop {
    name?: string | null;
    limit: number;
    offset: number;
}

export interface IShop extends ShopValues {
    readonly id?: number;
    shop_inline?: string;
    shop_link?: string;
    product?: Array<ProductProps>;
    product_id?: number;
}

export interface ShopValues extends ShopKeys {
    readonly shop_id?: number;
    readonly name: string;
    readonly about: string;
    readonly buyable: boolean;
    readonly photo: string | File | null;
}

export interface ShopKeys {
    project_id?: number;
    secretKey?: string;
}

export enum ShopOrderType {
    pay = 'Покупка',
    preorder = 'Предзаказ',
}

export interface ShopForm {
    readonly open: boolean;
    readonly setOpen: Dispatch<SetStateAction<boolean>>;
    readonly title: string;
    readonly initialValues: ShopValues;
    readonly onSave: ({ ...IShopCreate }) => void;
    readonly isSuccess?: boolean;
    readonly isEdit?: boolean;
    readonly loadingReadKeys?: boolean;
    readonly shopKeys?: ShopKeys;
    readonly successReadKeys?: boolean;
    readonly formik?: FormikProps<ShopValues>;
}

export interface FeedProps {
    readonly id?: number;
    readonly feed_url: string;
    readonly feed_frequency: number;
    readonly is_active: boolean;
}

export interface FeedResponseProps {
    readonly frequency: number;
    readonly is_active: boolean;
    readonly next_updated_at: Date;
    readonly shop_id: number;
    readonly url: string;
}

export interface FeedFormProps {
    readonly form: FormInstance<FeedProps>;
    readonly initial: FeedProps;
}
