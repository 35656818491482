import React, {
    Fragment,
    useEffect,
    useState,
    useRef,
    Dispatch,
    SetStateAction,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { App, Button, Divider, Input, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import {
    useReadProductCategoryQuery,
    useCreateProductCategoryMutation,
} from '../../services/categories';
import { useToggle } from '../../../../hooks/useToggle';
import { IProducts } from '../../interfaces/products.interface';
import { Uncategorized } from './categoryFilters';

type CategoryFilter = {
    shopId: number;
    filters?: any;
    setFilters?: Dispatch<SetStateAction<any>>;
    width?: number;
    formik?: FormikProps<IProducts>;
    type?: string;
};
export const CategoryFilter: React.FC<CategoryFilter> = props => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const { open, setOpen } = useToggle();

    const [name, setName] = useState('');
    const [list, setList] = useState([]);
    const [active, setActive] =
        useState<
            Array<{ id: number; key: number; value: string; text: string }>
        >();

    const inputRef = useRef<InputRef>(null);

    const { data, isLoading, isSuccess } = useReadProductCategoryQuery({
        shop_id: +props.shopId,
    });

    const [
        createCategory,
        {
            isLoading: isLoadingCreate,
            isSuccess: isSuccessCreate,
            data: dateCreate,
        },
    ] = useCreateProductCategoryMutation();

    useEffect(() => {
        if (isSuccess && data) {
            const categoriesList = data?.items.map(c => ({
                id: c.id,
                value: c.name,
                text: c.name,
            }));

            if (props.type === 'page') {
                categoriesList.unshift({
                    id: null,
                    value: 'Все категории',
                    text: 'Все категории',
                });
            }

            setList(categoriesList);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (props?.formik && list && !isSuccessCreate) {
            const active = list.filter(
                elem => elem.id === props.formik.values.category_id
            );
            setActive(active);
        }
    }, [props?.formik, list]);

    const checkCategory = list.some(elem => elem.value === name);
    const isBigDataList = useMemo(() => list.length > 50, [list]);

    useEffect(() => {
        if (checkCategory) {
            return notification.error({
                message: `Категория ${name} уже существует`,
            });
        }
    }, [name]);

    useEffect(() => {
        if (isSuccessCreate) {
            setOpen(false);
            if (props.type !== 'page') {
                setName('');
                const active = list.filter(elem => elem.id === dateCreate.id);
                setActive(active);
                props.formik.setFieldValue('category_id', dateCreate.id);
            }
        }
    }, [isSuccessCreate, list]);

    useEffect(() => {
        if (isSuccessCreate) {
            return notification.success({
                message: `Категория ${name} успешно создана`,
            });
        }
    }, [isSuccessCreate]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = async (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        e.preventDefault();

        const data = {
            shop_id: props.shopId || +id,
            name: name,
            parent_id: 0,
        };

        await createCategory(data);
    };

    return (
        <Fragment>
            <StyledSelect
                style={{ width: props?.width || 300 }}
                loading={isLoading}
                disabled={isLoading}
                onDropdownVisibleChange={() => setOpen(!open)}
                open={open}
                showSearch
                virtual={!isBigDataList}
                onChange={(e, category: { id: number }) => {
                    props.filters
                        ? props.setFilters({
                              ...props.filters,
                              category_id: category.id,
                          })
                        : props.formik.setFieldValue(
                              'category_id',
                              category.id
                          );
                }}
                defaultValue={
                    props.type === 'page' ? 'Все категории' : Uncategorized
                }
                value={active}
                options={list}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder='Название категории'
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button
                                disabled={
                                    isLoading ||
                                    name === '' ||
                                    checkCategory ||
                                    isLoadingCreate
                                }
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={addItem}
                            >
                                Добавить
                            </Button>
                        </Space>
                    </>
                )}
            />
        </Fragment>
    );
};

const StyledSelect = styled(Select)`
    max-width: 300px;
`;
