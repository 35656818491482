export const messages = {
    success: 'Файл выгрузки прийдет вам на почту в течении 10 мин',
    error: 'Произошла ошибки при выгрузке',
    invalidDates: 'Сначала нужно выбрать даты',
};

export const page = {
    button: 'Выгрузить заказы',
    hint: 'Раздел находится в разработке, но пока вы можете выгрузить все ваши заказы.',
};

export const statusList = [
    {
        label: 'Предзаказ',
        key: 'Предзаказ',
        text: 'Предзаказ',
        value: 'Предзаказ',
    },
    {
        label: 'Покупка',
        key: 'Покупка',
        text: 'Покупка',
        value: 'Покупка',
    },
    {
        label: 'Отменён',
        key: 'Отменён',
        text: 'Отменён',
        value: 'Отменён',
    },
    {
        label: 'Принят',
        key: 'Принят',
        text: 'Принят',
        value: 'Принят',
    },
    {
        label: 'В работе',
        key: 'В работе',
        text: 'В работе',
        value: 'В работе',
    },
    {
        label: 'Выполнен',
        key: 'Выполнен',
        text: 'Выполнен',
        value: 'Выполнен',
    },
];
