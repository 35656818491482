import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import { FeedProps, IShop, Shop } from '../interfaces/shop.interface';
import { PaginatedResponse } from '../../communities/interfaces/PaginatedResponse';
import {
    OrderProps,
    OrdersFilters,
    OrdersProps,
} from '../interfaces/orders.interface';

export const ordersApi = createApi({
    reducerPath: 'orders',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Orders'],

    endpoints: builder => ({
        readOrders: builder.query<
            PaginatedResponse<OrdersProps>,
            OrdersFilters
        >({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/shops/${params.shop_id}/orders?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: OrdersProps[] }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Orders'],
        }),

        readOrder: builder.query<OrderProps, number>({
            query: id => {
                return {
                    url: `/orders/${id}`,
                };
            },
            transformResponse: (res: { data: OrderProps }) => {
                return res.data;
            },
            providesTags: ['Orders'],
        }),

        updateOrderStatus: builder.mutation({
            query: body => ({
                method: 'PATCH',
                url: `/orders/${body.order_id}/update-status`,
                body: {
                    status: `${body.status}`,
                },
            }),
            invalidatesTags: ['Orders'],
        }),
    }),
});

export const {
    useLazyReadOrdersQuery,
    useLazyReadOrderQuery,
    useUpdateOrderStatusMutation,
} = ordersApi;
