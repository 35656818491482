export interface IAuthors {
    readonly about: string;
    readonly author_id: number;
    readonly name: string;
    readonly photo: string;
    readonly user_id: number;
}

export interface IProducts {
    id?: number;
    readonly shop_id?: number;
    readonly title: string;
    readonly description: null | string;
    readonly images: [] | string | File | null;
    readonly price: number;
    readonly old_price: number | null;
    readonly buyable: boolean;
    readonly category_id: number;
    readonly status: ProductStatus;
}

export interface ProductProps {
    readonly shop_id?: number;
    readonly description: string | null;
    readonly id: number;
    readonly images: Array<{ id: number; file: string }>;
    readonly title: string;
    readonly buyable: boolean;
    readonly price?: string;
    readonly old_price?: number | null;
    readonly product_id?: number;
    readonly status: ProductStatus;
    readonly category_name: string;
}

export interface IProductsPublic<T> {
    readonly items: Array<T>;
    readonly total: number;
}

export interface IProductFilters {
    id?: number;
    limit: number;
    offset: number;
    title?: string;
}

export interface ProductsFilters {
    title: string;
    offset: number;
    limit: number;
    category_id?: number | null;
    status?: Array<number>;
    page?: number;
}

export enum ProductsLisType {
    active = 'active',
    archive = 'archive',
    disabled = 'disabled',
}

export enum ProductStatus {
    active = 1,
    archived = 2,
    disabled = 3,
}

export interface ProductBuy {
    readonly telegram_user_id: string | number;
    readonly product_id: number;
    readonly address: string;
    readonly email: string;

    phone?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    is_mobile?: boolean;
}

export interface ProductPay {
    redirect?: string;
    order_id?: number;
}
