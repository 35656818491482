import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import {
    FeedProps,
    FeedResponseProps,
    IShop,
    Shop,
} from '../interfaces/shop.interface';
import { PaginatedResponse } from '../../communities/interfaces/PaginatedResponse';

export const shopsApi = createApi({
    reducerPath: 'shops',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Shops', 'Products'],

    endpoints: builder => ({
        readShops: builder.query<PaginatedResponse<IShop>, Shop>({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/shops/my?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: Array<IShop> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Shops'],
        }),

        readShopsPublic: builder.query<PaginatedResponse<IShop>, Shop>({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/shops/list?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: Array<IShop> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Shops'],
        }),

        readShopById: builder.query({
            query: id => ({
                url: `/shops/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IShop }) => {
                return response.data;
            },
            providesTags: ['Shops'],
        }),

        createShop: builder.mutation<IShop, FormData>({
            query: body => ({
                url: '/shops',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IShop }) => {
                return response.data;
            },
            invalidatesTags: ['Shops'],
        }),

        updateShop: builder.mutation({
            query: ({ id, body }) => ({
                url: `/shops/${id}`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IShop }) => {
                return response.data;
            },
            invalidatesTags: ['Shops'],
        }),

        deleteShop: builder.mutation({
            query: id => ({
                url: `/shops/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Shops'],
        }),

        readYookassa: builder.query({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/yookassa/exchange?${queryArgs}`,
                    method: 'POST',
                };
            },
            transformResponse: (response: { data: IShop }) => {
                return response.data;
            },
            providesTags: ['Shops'],
        }),

        createShopAllProductsBuyable: builder.mutation<
            any,
            { id: number; buyable: number }
        >({
            query: ({ id, ...body }) => ({
                url: `/shops/products/buyable/${id}`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IShop }) => {
                return response;
            },
            invalidatesTags: ['Shops', 'Products'],
        }),

        readOrdersExport: builder.query({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket',
                });
                return {
                    url: `/market/orders/export?${queryArgs}`,
                };
            },
            transformResponse: res => {
                return res;
            },
        }),

        createFeed: builder.mutation<any, FeedProps>({
            query: payload => ({
                url: `/shops/feed/${payload.id}`,
                method: 'POST',
                body: { ...payload },
            }),
            transformResponse: (response: { data: any }) => {
                return response;
            },

            transformErrorResponse: (response: { data: any }) => {
                return response;
            },
            invalidatesTags: ['Shops'],
        }),
        readFeed: builder.query<FeedResponseProps, number>({
            query: id => {
                return {
                    url: `/shops/feed/${id}`,
                };
            },
            transformResponse: (response: { data: FeedResponseProps }) => {
                return response.data;
            },
        }),
    }),
});

export const {
    useReadShopsQuery,
    useLazyReadShopsQuery,
    useLazyReadShopByIdQuery,
    useReadShopsPublicQuery,
    useCreateShopMutation,
    useReadShopByIdQuery,
    useDeleteShopMutation,
    useUpdateShopMutation,
    useLazyReadYookassaQuery,
    useCreateShopAllProductsBuyableMutation,
    useLazyReadOrdersExportQuery,
    useCreateFeedMutation,
    useLazyReadFeedQuery,
} = shopsApi;
