import React from 'react';
import { Form, Input, InputNumber, Switch } from 'antd';

import { FeedFormProps } from '../../interfaces/shop.interface';

export const FeedForm = (props: FeedFormProps) => {
    const { form, initial } = props;

    return (
        <Form
            wrapperCol={{
                xs: 24,
                md: 16,
            }}
            form={form}
            name='feeds'
            initialValues={initial}
            layout={'horizontal'}
            autoComplete='off'
            requiredMark={false}
        >
            <Form.Item
                labelCol={{
                    span: 24,
                }}
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
                label='Ссылка на товарный фид'
                name='feed_url'
            >
                <Input required placeholder={'Укажите ссылку на фид'} />
            </Form.Item>

            <Form.Item
                labelCol={{
                    span: 24,
                }}
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
                label='Частота обновления (часы)'
                name='feed_frequency'
            >
                <InputNumber required maxLength={5} max={99999} min={1} />
            </Form.Item>

            <Form.Item
                label={'Активировать фид'}
                valuePropName='checked'
                style={{ marginBottom: 0 }}
                name='is_active'
            >
                <Switch defaultChecked />
            </Form.Item>
        </Form>
    );
};
