import React from 'react';
import { Button, Divider, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';

import { BannerControlsProps } from '../../interfaces/banners.interface';

export const ManageControls = (props: BannerControlsProps) => {
    const { onSave, isSubmittable } = props;
    const navigate = useNavigate();

    const handleCancel = () => {
        return navigate(-1);
    };

    return (
        <>
            <Divider />
            <Flex gap={24}>
                <Button
                    disabled={!isSubmittable}
                    type={'primary'}
                    onClick={onSave}
                >
                    Сохранить
                </Button>
                <Button onClick={handleCancel}>Отмена</Button>
            </Flex>
        </>
    );
};
