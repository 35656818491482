import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Flex, Form, Typography } from 'antd';

import {
    useCreateFeedMutation,
    useLazyReadFeedQuery,
} from '../../services/shops';
import { FeedForm } from '../../views/Feeds/FeedForm';
import { initial, messages } from '../../utils/feeds';
import { FeedNotifications } from '../../views/Feeds/FeedNotifications';

const { Text } = Typography;

export const FeedsSettingsContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const [createFeed, { isError, isSuccess, error }] = useCreateFeedMutation();
    const [getFeed, { data }] = useLazyReadFeedQuery();

    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getFeed(+id);
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
                feed_frequency: data.frequency,
                feed_url: data.url,
            });
        }
    }, [data]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    const handleSave = async () => {
        const payload = {
            id: +id,
            feed_url: values.feed_url,
            feed_frequency: values.feed_frequency,
            is_active: values.is_active,
        };

        await createFeed(payload);
    };

    return (
        <Flex vertical gap={24}>
            <FeedNotifications
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            />
            <Text style={{ marginTop: 24 }}>{messages.hint}</Text>
            <FeedForm form={form} initial={initial} />
            <Divider style={{ margin: 0 }} />
            <Flex vertical={false} gap={24}>
                <Button
                    disabled={!isSubmittable}
                    type={'primary'}
                    onClick={handleSave}
                >
                    {messages.save}
                </Button>
                <Button onClick={() => navigate(-1)}>{messages.cancel}</Button>
            </Flex>
        </Flex>
    );
};
