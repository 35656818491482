import React, { useEffect, useState } from 'react';
import { App, Form, Input } from 'antd';

import Secondary from '../../../../shared/ui/SecondaryText';

export const OldPrice = props => {
    const { notification } = App.useApp();
    const { formik } = props;
    const [value, setValue] = useState(formik?.values?.old_price || '');
    const [numberError, setNumberError] = useState(false);

    useEffect(() => {
        if (value && numberError) {
            return notification.info({
                message:
                    'Значение поля "Цена без скидки" должно быть целым числом',
            });
        }
    }, [value, numberError]);

    const formatterToNumber = (value: string) => {
        if (!value) return null;
        return Number.parseFloat(value.replace(/\./g, '').replace(/,/g, '.'));
    };

    const handleChange = value => {
        const numberValue = formatterToNumber(value);
        const stringValue = numberValue
            ? numberValue.toLocaleString('de-DE')
            : '';

        if (Math.trunc(numberValue).toString().length > 7) {
            return;
        }

        if (isNaN(numberValue)) {
            return;
        }

        setValue(stringValue);
        formik.setFieldValue('old_price', numberValue);

        if (numberValue && !Number.isSafeInteger(numberValue)) {
            setNumberError(true);
            setValue(Math.trunc(numberValue).toLocaleString('de-DE'));
            formik.setFieldValue('old_price', Math.trunc(numberValue));
        }
    };

    return (
        <Form layout={'vertical'}>
            <Form.Item
                label={'Цена без скидки, руб:'}
                style={{ marginBottom: 0 }}
            >
                <Input
                    value={value}
                    allowClear
                    placeholder={`Укажите цену товара без скидки`}
                    onChange={e => {
                        handleChange(e.target.value);
                    }}
                    style={{ width: props.width }}
                />
                <br />
                <Secondary>Должна быть больше цены товара</Secondary>
            </Form.Item>
        </Form>
    );
};
