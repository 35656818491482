import type { TabsProps } from 'antd';

export const items: TabsProps['items'] = [
    {
        key: 'products',
        label: `Товары`,
    },
    {
        key: 'archive',
        label: `Архив`,
    },
    {
        key: 'orders',
        label: `Заказы`,
    },
    {
        key: 'banners',
        label: `Баннеры`,
    },
];
