import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Flex,
    Input,
    InputRef,
    Space,
    Table,
    Dropdown,
    TableColumnType,
    Typography,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
    useLazyReadOrdersQuery,
    useUpdateOrderStatusMutation,
} from '../../services/orders';
import { rub } from '../../../../shared/contants/styles';
import { renderSplitPrice } from '../../../../utils/common/renderSpiltPrice';
import { useTheme } from '../../../../hooks/useTheme';
import { updateOrderStatus } from '../../utils/updateOrderStatus';
import { OrdersFilters } from '../../interfaces/orders.interface';
import { statusList } from '../../utils/orders';

const { Title, Text, Link } = Typography;

interface DataType {
    readonly key: React.Key;
    readonly phone: string;
    readonly username: string;
    readonly telegram_username: string;
    readonly id: number;
    readonly created_at: string;
}

type DataIndex = keyof DataType;
const OrdersContainer = () => {
    const { id } = useParams();
    const { colorPrimary } = useTheme();
    const navigate = useNavigate();
    const searchInput = useRef<InputRef>(null);

    const [filters, setFilters] = useState<OrdersFilters>({
        shop_id: +id,
        limit: 10,
        offset: 0,
    });

    const [values, setValues] = useState({});

    const [getOrders, { data, isLoading, isFetching }] =
        useLazyReadOrdersQuery();
    const [updateStatus, { isLoading: isLoadingUpdate }] =
        useUpdateOrderStatusMutation();

    useEffect(() => {
        if (filters) {
            getOrders(filters, true);
        }
    }, [filters]);

    const handleOrderView = (id: number) => {
        return navigate(`${id}`);
    };

    const handleSort = (field, direction) => {
        const sortDirection = direction => {
            switch (direction) {
                case 'ascend':
                    return 'asc';
                case 'descend':
                    return 'desc';
            }
        };
        return setFilters({
            ...filters,
            sort_by: field,
            sort_direction: sortDirection(direction),
        });
    };

    const handleFilters = value => {
        if (value === filters.status) {
            setFilters({
                ...filters,
                status: null,
            });
        } else {
            setFilters({
                ...filters,
                status: value,
            });
        }
    };

    const handleSearch = (value, dataIndex) => {
        return setValues({ ...values, [dataIndex]: value });
    };

    const onConfirm = () => {
        return setFilters({ ...filters, ...values });
    };

    const handleReset = dataIndex => {
        setValues({ ...values, [dataIndex]: null });
        setFilters({ ...filters, [dataIndex]: null });
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex,
        fieldTitle: string
    ): TableColumnType<DataType> => ({
        filterDropdown: ({ clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Поиск по ${fieldTitle}`}
                    value={filters[dataIndex]}
                    onChange={e => handleSearch(e.target.value, dataIndex)}
                    onPressEnter={onConfirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => {
                            onConfirm();
                            close();
                        }}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Поиск
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(dataIndex);
                            clearFilters();
                            close();
                        }}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Очистить
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            close();
                        }}
                    >
                        Отмена
                    </Button>
                </Space>
            </div>
        ),
        filteredValue: filters[dataIndex],
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? colorPrimary : undefined,
                }}
            />
        ),
    });

    const menuProps = item => {
        return {
            items: statusList,
            onClick: value => updateOrderStatus(value, item, updateStatus),
        };
    };

    const columns = [
        {
            title: <Text strong>Номер заказа</Text>,
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b, sortOrder) => handleSort('id', sortOrder),
            ...getColumnSearchProps('id', 'номеру'),
        },

        {
            title: <Text strong>Дата заказа</Text>,
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => (
                <Flex>
                    <Text>{dayjs(created_at).format('DD.MM.YYYY')}</Text>
                </Flex>
            ),
            sorter: (a, b, sortOrder) => handleSort('created_at', sortOrder),
        },

        {
            title: <Text strong>Статус</Text>,
            dataIndex: 'status',
            filters: statusList,
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <Flex vertical gap={8}>
                        {statusList.map((status, index) => (
                            <Text
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => handleFilters(status.value)}
                            >
                                {status.label}
                            </Text>
                        ))}
                    </Flex>
                </div>
            ),
            render: (status, record) => (
                <Dropdown menu={menuProps(record)}>
                    <Button size={'small'}>
                        <Space>
                            {status}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            ),
        },

        {
            title: <Text strong>Сумма заказ</Text>,
            dataIndex: 'total_price',
            key: 'total_price',
            render: total_price => (
                <Flex>
                    <Text>
                        {renderSplitPrice(total_price)} {rub}
                    </Text>
                </Flex>
            ),
        },

        {
            title: <Text strong>Телефон</Text>,
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone', 'телефону'),
        },

        {
            title: <Text strong>Юзернейм</Text>,
            dataIndex: 'username',
            key: 'username',
            render: username =>
                username && (
                    <Flex>
                        <Text>@{username}</Text>
                    </Flex>
                ),
            ...getColumnSearchProps('telegram_username', 'юзернейму'),
        },

        {
            title: '',
            key: 'operation',
            fixed: 'right',
            render: ({ id }) => (
                <Link onClick={() => handleOrderView(id)}>Подробнее</Link>
            ),
        },
    ];

    const pagination = {
        total: data?.total,
        showTotal: () => `Заказов: ${data?.total}`,
        onChange: (page, pageSize) => {
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: true,
    };

    return (
        <Flex gap={24} vertical>
            <Title style={{ margin: 0 }} level={4}>
                История заказов
            </Title>
            <Table
                loading={isLoading || isLoadingUpdate || isFetching}
                pagination={pagination}
                dataSource={data?.items}
                rowKey={record => record.id}
                columns={columns}
            />
            <Outlet />
        </Flex>
    );
};

export default OrdersContainer;
