import React, { useEffect } from 'react';
import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import { useLazyReadOrderQuery } from '../../services/orders';
import { rub } from '../../../../shared/contants/styles';
import { OrdersProducts } from '../../components/OrdersProducts';

const { Text, Title, Link } = Typography;

const renderOrderInfo = (
    name: string,
    value: string | number,
    type?: string
) => {
    return (
        <Row>
            <Col span={12}>
                <Text>{name}</Text>
            </Col>
            <Col span={12}>
                {type === 'date' && (
                    <Text>{dayjs(value).format('DD.MM.YYYY')}</Text>
                )}
                {type === 'user' && value && <Link>@{value}</Link>}
                {type === 'link' && <Link>{value}</Link>}
                {type === 'price' && value !== null && (
                    <Text>
                        {value} {rub}
                    </Text>
                )}
                {type === undefined && <Text>{value}</Text>}
            </Col>
            <Divider style={{ margin: '16px 0' }} />
        </Row>
    );
};

export const OrdersModal = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();

    const [getOrder, { data, isLoading }] = useLazyReadOrderQuery();

    useEffect(() => {
        if (orderId) {
            getOrder(+orderId, true);
        }
    }, [orderId]);

    const handleCloseModal = () => {
        navigate(-1);
    };

    return (
        <GenericModal
            title={`Заказ №${orderId}`}
            width={720}
            open={true}
            onCancel={handleCloseModal}
            destroyOnClose
            footer={null}
        >
            {isLoading ? (
                <Skeleton />
            ) : (
                <div style={{ marginTop: '32px' }}>
                    {renderOrderInfo('Дата заказа', data?.created_at, 'date')}
                    {renderOrderInfo('Статус', data?.status_name)}
                    {renderOrderInfo(
                        'Общая стоимость',
                        data?.total_order_sum,
                        'price'
                    )}
                    {renderOrderInfo('Сумма заказа', data?.total, 'price')}
                    {renderOrderInfo(
                        'Доставка',
                        data?.delivery.delivery_sum,
                        'price'
                    )}
                    {renderOrderInfo(
                        'ФИО покупателя:',
                        data?.delivery.full_name
                    )}
                    {renderOrderInfo('Телефон', data?.delivery.phone)}
                    {renderOrderInfo(
                        'Юзернэйм',
                        data?.telegram_username,
                        'user'
                    )}
                    {renderOrderInfo('Адрес доставки:', data?.delivery.address)}
                    {renderOrderInfo('Почта:', data?.delivery.email, 'link')}
                </div>
            )}

            <Title style={{ marginBottom: 0 }} level={5}>
                Список товаров
            </Title>
            <OrdersProducts items={data?.product_list} />
        </GenericModal>
    );
};
